/* social-share */

.social_share {
  color: #fff;
  margin-bottom: 40px;
  padding: 0 10px;
}
.social_share h3 {
  font-size: 18px;
  margin-bottom: 20px;
}
.social_share ul {
  /* margin: 10px !important;
  padding: 10px; */
  display: inline-block;
  justify-content: space-between;
}
.modal-content {
  background-color: #282828;
}

/* social-share */

.my-masonry-grid_column > div {
  background: #2f2f2f;
  margin-bottom: 6px;
  cursor: pointer;
  position: relative;
}
.my-masonry-grid_column img,
.my-masonry-grid_column video {
  max-width: 100%;
}

.my-masonry-grid {
  display: flex;
  margin-left: 0;
  width: auto;
}
.my-masonry-grid_column {
  width: 49% !important;
}
.my-masonry-grid .my-masonry-grid_column:nth-child(2) {
  margin-left: 2%;
}

.blur-image-11 {
  background-color: #6e3cca;
  width: 100%;
}

.img-section img {
  height: 100%;
  object-fit: cover !important;
  width: 100%;
  min-height: 150px;
  max-height: 150px;
}
.d-block {
  padding: 5px;
}
.d-block img {
  width: 100%;
  height: 100%;
}

span.range-input {
  margin-left: inherit;
}

.canvas-header .effects-ul .close-li {
  width: 75px;
}

ul.effects-ul li {
  min-width: 60px;
  position: relative;
}
.canvas-header .effects-ul .close-li img {
  max-width: 11px;
  margin-right: 4px;
}

.canvas-header .effects-ul li.tick-li {
  width: 70px;
}
.canvas-header .effects-ul .tick-li img {
  max-width: 15px;
  margin-right: 5px;
}
.canvas-header ul li img {
  max-width: 16px;
}
.meme-filter-invert {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.meme-filter-Sepia {
  -webkit-filter: sepia(100%);
  filter: sepia(100%);
}

.meme-filter-Saturation {
  -webkit-filter: saturate(0.5);
  filter: saturate(0.5);
}

.meme-filter-Hue {
  filter: hue-rotate(-45deg);
  -webkit-filter: hue-rotate(45deg);
}

.meme-filter-Blur {
  filter: blur(1px);
  -webkit-filter: blur(1px);
}

.meme-filter-grayscale {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.canvas-header li.font-color .text,
.canvas-header li.background-color .text,
.canvas-header li.opacity .text {
  margin-left: 0px;
}
span.opacity-bar {
  margin: 0px 10px;
}
input#opacity-range,
input#zoom-range {
  height: 1px;
  -webkit-appearance: none;
  background: #949494;
}

input[type="text color"] {
  width: 60px !important;
  pointer-events: none;
}
.hide-arrow {
  right: -24px;
}
.menu-block ul li a.download {
  color: #41a1ff;
}
.menu-block ul li a.save {
  color: #77e060;
  margin-left: 20px;
}
a.save img,
a.download img {
  width: 24px;
  margin: 10px 10px 10px 0px;
}
.changes {
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  color: #c0c0c0;
  margin-right: 20px;
}
span.round-corner {
  width: 45px;
}
.switch {
  position: absolute;
  left: -33px;
  top: -1px;
  height: 20px;
  overflow: hidden;
}
.right-canvas-section {
  width: 100% !important;
}
.canvas-header ul {
  margin-left: 0px !important;
}

select,
.font-size,
input#zoom-range,
input#opacity-range,
.trash,
.duplicate,
input#roundedCorner {
  cursor: pointer;
}
/* .rc-color-picker-trigger {
  pointer-events: all !important;
} */
span.color-box {
  pointer-events: none;
}
.rounded-box {
  cursor: pointer;
  border-radius: 5px;
}
span.error-text {
  width: 100%;
  color: #afafaf;
  text-align: center;
  float: left;
}

span.new-templates.img-section img {
  max-width: 100% !important;
  height: 100%;
  min-height: 100% !important;
  max-height: 100% !important;
}

.custom-loader {
  position: absolute;
  background: rgba(0, 0, 0, 0.56);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.loader_text {
  font-weight: 700;
  color: #fff;
  font-size: 14px;
}

ul.palette li.palette-box {
  border: 1px solid #c7c7c7;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin-right: 4px;
}
ul.palette li.palette-box {
  position: relative;
  z-index: 999;
}
ul.palette li {
  display: inline-block;
  width: 27px;
  border-radius: 5px;
  height: 27px;
  margin-left: 5px;
  border: 1px solid transparent;
  transition: all 0.5s;
}
li.palette-box img {
  max-width: 20px;
  float: left;
}

ul.print-dropdown.palette-dropdown {
  width: 230px;
  left: 0;
  margin-top: 10px;
  overflow-y: scroll;
  height: auto;
  min-height: 348px;
}
ul.print-dropdown {
  z-index: 3;
}
.print-dropdown,
.print-dropdown-download,
.transparency-dropdown {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(14, 19, 24, 0.07), 0 2px 12px rgba(14, 19, 24, 0.2);
  position: absolute;
  right: 0;
  min-width: 230px;
  max-width: 230px;
  top: 35px;
}
ul.print-dropdown.palette-dropdown h5 {
  color: #525252;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 10px;
}

ul.print-dropdown.palette-dropdown li {
  display: block;
  width: 100%;
  padding: 0 12px;
  margin: 5px 0px;
  border: none;
  float: left;
  min-height: 70px;
}
.palette-box ul.print-dropdown.palette-dropdown img,
ul.print-dropdown.palette-dropdown li {
  z-index: auto;
}
ul.palette li {
  cursor: pointer;
}
ul.document-color {
  margin-bottom: 20px;
  float: left;
}

.palette ul.document-color li {
  width: 27px;
  min-height: 27px;
  float: left;
  height: 27px;
  margin-left: 5px;
  border: 1px solid #808080;
}

/* .sidebar-close .canvas-header {
  width: calc(100vw - 85px);
} */

li.outer-color:hover,
li.palette-box:hover {
  cursor: pointer;
  box-shadow: 0 0 0 2px #ffcf58, inset 0 0 0 2px #2d3137;
  padding: 5px;
  border: 1px solid #2d3137;
}

ul.document-color li:hover,
.new-color:hover {
  cursor: pointer;
  box-shadow: 0 0 0 2px #ffcf58, inset 0 0 0 2px #fff !important;
  padding: 2px !important;
  border: 1px solid #fff !important;
}
img.new-color {
  margin-left: 5px;
}

.palette-box ul.print-dropdown.palette-dropdown img {
  max-width: 27px;
  height: 27px;
  border-radius: 5px;
}

.top-menu ul li {
  padding-right: 20px;
}

.social_share ul {
  width: 100%;
  text-align: center;
}

.design.d-block {
  text-align: center;
  text-transform: uppercase;
  font-family: GeorgeRoundedSemibold;
  font-size: 10px;
  font-weight: 500;
  color: #c7c7c7;
  background-color: #2f2f2f;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: 0px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 0px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: #2f2f2f;
  margin-bottom: 6px;
}
.my-masonry-grid .my-masonry-grid_column:nth-child(2) {
  margin-left: 2%;
}
.my-masonry-grid_column {
  width: 49% !important;
}
.my-masonry-grid_column img {
  max-width: 100%;
}

ul.category-section.mosiac li {
  width: auto;
  margin-left: 0;
  float: none;
  color: #fff;
}

ul.category-section.mosiac {
  -webkit-column-count: 2;
  column-count: 2;
  grid-column-gap: 0.35rem;
  -webkit-column-gap: 0.35rem;
  column-gap: 0.35rem;
}
.opacity span.text {
  margin-right: 7px;
}
ul.left-icons-list span.d-block.my-dsg {
  width: 57px;
  height: 57px;
}

@-moz-document url-prefix() {
  .custom-range,
  li.opacity input {
    height: 15px;
  }
}

ul.transparency-dropdown {
  min-width: 350px;
  padding: 15px;
  /* z-index: 1; */
  z-index: 999;
  margin-top: 8px;
}
.canvas-header ul {
  height: 50px;
  align-items: center;
  display: flex;
  margin-left: 10px;
}
.print-dropdown,
.print-dropdown-download,
.transparency-dropdown {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(14, 19, 24, 0.07), 0 2px 12px rgba(14, 19, 24, 0.2);
  position: absolute;
  right: 0;
  min-width: 275px;
  max-width: 300px;
  top: 35px;
}

li.transparency {
  position: relative;
}
li.transparency:hover {
  cursor: pointer;
}
ul.transparency-dropdown {
  position: absolute;
  background: #fff;
  top: 33px;
  right: 0;
  height: auto;
}
span.opacity-zoom-outer-width {
  width: 165px;
}
span.opacity-zoom-outer-width input {
  width: 100%;
}

span.range-input {
  margin-left: 10px;
  width: 45px;
}
span.range-input input {
  width: 100%;
  border-radius: 3px;
}

.canvas-header ul li:hover {
  background: #eaeaea;
  cursor: pointer;
}
.canvas-header ul li.font-color {
  padding: 0 3px;
}

ul.transparency-dropdown li {
  justify-content: space-between;
}
.canvas-left-header ul li.font-family,
.canvas-left-header ul li.font-size,
.canvas-left-header ul li.background-color {
  margin: 0 0px;
  border: 1px solid #999;
}

ul.effects-ul li.font-color,
ul.effects-ul li.background-color {
  border: none;
  min-width: auto;
}
ul.effects-ul li.font-color:hover,
ul.effects-ul li.background-color:hover {
  cursor: default;
}
.canvas-left-header ul li.font-color:hover,
.canvas-left-header ul li.background-color:hover {
  background: none;
}
.canvas-left-header ul li.font-family,
.canvas-left-header ul li.font-size,
.canvas-left-header ul li.background-color {
  border: none;
}

ul.effects-ul select,
ul.effects-ul li.font-size {
  padding: 0;
}
ul.effects-ul select:focus,
ul.effects-ul li.font-size:focus {
  outline: none;
  box-shadow: none;
}
.font-color input[type="text color"]::placeholder,
.background-color input[type="text color"]::placeholder {
  color: #212529;
  font-size: 13px;
}
.font-color input[type="text color"] {
  width: 65px !important;
  cursor: default;
}
.background-color input[type="text color"] {
  width: 70px !important;
  cursor: default;
}
.canvas-header .effects-ul li.font-family select {
  font-size: 13px;
}
.canvas-header ul li.align {
  min-width: 36px !important;
  height: 36px;
}
ul.effects-ul li.font-size {
  /* min-width: 36px; */
  margin: 0 10px;
}
.canvas-left-header .effects-ul li:first-child {
  margin-left: 5px;
}
span.adjust {
  margin-right: 16px;
}
.canvas-header ul.transparency-dropdown li:hover {
  background: none;
}

li.font-color {
  padding-left: 0;
}
.round-corner {
  cursor: default;
}
li.close-li.rounded-box {
  margin-left: 5px;
}
.row.search-box input {
  color: #fff;
  font-size: 13px;
  line-height: 41px;
}
.input-group-prepend.border-0 button.btn img {
  filter: brightness(200%);
}
ul.filers-ul li {
  height: 79px;
  width: 79px;
  background: none;
  padding: 6px;
  box-shadow: none;
  display: inline-block;
  margin: 0 0 20px;
  transition: all 0.3s;
}
ul.filers-ul li button {
  width: 100%;
  float: left;
  padding: 2px;
  background: none !important;
  border: none;
}
ul.filers-ul li .img-box.active-border,
ul.filers-ul li:hover .img-box {
  box-shadow: 0 0 0 2px #f9b200, inset 0 0 0 24px #fff;
  border-radius: 5px;
  transition: all 0.3s;
}
.filers-ul li span {
  float: left;
  text-align: center;
  transition: all 0.3s;
}
ul.filers-ul li img {
  max-width: 100%;
  border-radius: 5px;
  border: 2px solid transparent;
  transition: all 0.3s;
}
span.text-box.text-center {
  color: #fff;
  font-size: 13px;
  margin-top: 4px;
}
ul.filers-ul li span {
  float: left;
  text-align: center;
  transition: all 0.3s;
}
ul.filers-ul li span.img-box {
  width: auto;
  height: auto;
  margin: 0;
}

ul.filers-ul li button:focus {
  outline: none;
}

.flip-vh span,
.align span {
  line-height: 0px;
}
#myTab li {
  width: 49%;
  margin-left: 2%;
}
#myTab li:first-child {
  margin-left: 0;
}

@media (max-height: 700px) {
  .col-12.text-center.memes img {
    margin-bottom: 10px;
    margin-top: 0px;
  }
  .login-bg {
    padding: 15px;
  }
}

button.btn.btn-primary.image-video.w-100 {
  background: #fff;
  border: none;
  color: #2f2f2f;
}

button.btn.btn-primary.image-video.w-100 {
  background: linear-gradient(45deg, #9d00ff, #48e7fa) !important;
  border: none;
  color: #fff;
  transition: all 0.2s;
  height: 40px;
  border-radius: 4px;
}

button.btn.btn-primary.image-video.w-100 input {
  display: none;
}

.instagram-edit .input-group.round-search {
  width: 170px;
  float: left;
  height: 35px;
}

.row.search-box input {
  color: #fff;
  font-size: 13px;
  line-height: 14px;
}
span.error-text {
  width: 100%;
  color: #afafaf;
  text-align: center;
  float: left;
}

ul.effects-ul li.font-size select::before,
ul.effects-ul li.font-family select::before {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #f00;
  position: absolute;
  content: “”;
  right: 0px;
}

ul.effects-ul li.font-size select,
ul.effects-ul li.font-family select {
  appearance: none;
}

li.font-size .select-border-family select {
  font-size: 13px;
}

.form-control.bg-none.border-0::placeholder {
  line-height: 43px;
}

.show-arrow {
  position: absolute;
  top: 50%;
  /* left: 60px; */
  left: 52px;
  z-index: -1;
  cursor: pointer;
}

.search-box .round-search input::placeholder {
  color: #fff;
  height: 42px;
  line-height: 42px !important;
  background: transparent !important;
}
.search-box .round-search input::-webkit-input-placeholder {
  color: #fff;
  height: 42px;
  line-height: 42px !important;
  background: transparent !important;
}
.search-box .round-search input::-moz-placeholder {
  color: #fff;
  line-height: 30px !important;
}
.search-box .round-search input {
  background: transparent !important;
}

.create-template {
  color: #fff;
}

.menu-block .create-template img {
  filter: invert(1);
  max-width: 24px;
  margin-right: 10px;
}
.css-2b097c-container {
  display: inline-block;
  width: 70%;
}
button.create-template {
  max-width: 25%;
  margin-left: 5%;
  display: inline-block;
}

.css-1hb7zxy-IndicatorsContainer {
  background: #fff;
}

.css-g1d714-ValueContainer {
  border: 0px;
}
.css-yk16xz-control:firts-child:focus {
  box-shadow: none !important;
  border: none !important;
}
.css-1pahdxg-control {
  border-color: #ddd !important;
  box-shadow: none !important;
}
.create-template-modal .modal-header {
  border-bottom: none;
}
.redo-img.disabled,
.undo-img.disabled {
  opacity: 0.5;
  color: #fff !important;
}
.redo-img.disabled,
.redo-img {
  max-width: 20px;
}
.redo-img.disabled:hover {
  cursor: default;
}

.redo-img,
.undo-img {
  color: rgb(0, 196, 204) !important;
  padding-right: 0;
  font-size: 22px;
  line-height: 0px;
}
.top-menu ul li.redo-li {
  margin-left: 0;
}

div#memesWatermark {
  /* position: relative; */
  position: absolute;
  bottom: 8px;
  right: 8px;
  cursor: pointer;
  z-index: 4;
}

button#watermark {
  border: none;
  bottom: 0;
  right: 0;
  position: absolute;
  margin: 5px;
  /* margin: 10px; */
  /* width: 100px; */
  /* padding: 0 10px; */
  transition: all 0.3s;
}
button#watermark:hover {
  opacity: 0.5;
  transition: all 0.3s;
}
button#watermark:focus {
  border: none;
  outline: none;
  background: #fff;
}
/* button#watermark img {
  max-width: 100%;
  height: 25px;
} */
#memesWatermark img {
  max-width: 100%;
  height: 40px;
  /* border-radius: 8px; */
}
ul.transparency-dropdown {
  z-index: 5;
}
.watermark-dialog {
  z-index: 5;
}
#watermark {
  z-index: 5;
}

.undo-redo-btn {
  font-size: 22px;
}

.login-bg {
  background: url("../images/icons/login-bg.png");
  background-repeat: no-repeat;
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
}
.login-box-container {
  color: #fff;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  width: 490px;
  height: 300px;
  background: #1c1c1c;
  border-radius: 30px;
  padding: 0 55px;
}

.test1 {
  position: absolute;
  /* z-index: 0; */
  background: red;
  padding: 0;
  list-style: none;
  margin: 0px;
  top: 38px;
  border-radius: 3px;
  width: 100%;
  /* left: -10px; */
}

.test2 {
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
}
#ddlCars {
  min-height: 190px;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  width: 300px;
  display: contents;
}
.canvas-header ul li.font-family {
  width: 130px;
}

.memes_text_fonts__indicator-separator {
  display: none;
}
.test_beta .memes_text_fonts__menu {
  margin-top: 0;
}
.memes_text_fonts__menu .memes_text_fonts__menu-list {
  padding-top: 0;
  padding-bottom: 0;
}

.memes_text_fonts__indicators {
  background-color: #ddd;
}

.font_comb_img li img {
  min-width: unset;
  min-height: unset;
}
.instagram-edit {
  display: flex;
  justify-content: center;
  align-items: center;
}
button#watermark {
  border-radius: 8px;
}

button#watermark img {
  border-style: unset;
  border-radius: 6px;
}
.social_share ul li {
  display: inherit;
  margin: 0 10px;
}

/* elements module */
.content-design {
  display: flex;
  flex-direction: column;
}

.content-design .img-box {
  height: 100px;
  width: 100px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.background-none {
  background: none;
  /* background: yellowgreen; */
}

.background-black {
  background: #202122;
}

.content-design .canvas_list_item img {
  max-width: 100%;
  height: auto;
}
.content-design .list_heading,
.content-design .see-all {
  font-size: 14px;
  color: #fff;
  display: inline-block;
  float: left;
  margin-bottom: 15px;
  text-transform: capitalize;
  /* margin-top: 30px; */
}
.content-design .see-all {
  float: right;
  font-size: 13px;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.content-design .see-all:hover {
  cursor: pointer;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
ul.category-section.admin-li li span.img-section {
  height: auto;
}
span.img-section {
  height: 113px;
  float: left;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  background: #3e3e3e;
  transition: 0.2s all ease;
}
.see-more-elements {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}
.see-more-elements img {
  width: 20px !important;
  height: 20px !important;
}
ul.category-section.admin-li.elements-section li {
  width: 150px;
}
ul.category-section.admin-li.elements-section li:nth-child(2n) {
  margin-left: 20px;
}
ul.category-section.admin-li.elements-section span.img-section img {
  object-fit: contain !important;
}

/* elements module ends */

/* upload paste url feature */
span.or {
  color: #fff;
  margin: 6px 0px;
  display: block;
}

.error {
  width: 100%;
  color: #c50808;
  text-align: left;
  margin: 6px;
}
button.tarnsparent-bg img {
  width: 30px;
}
button.tarnsparent-bg {
  margin-left: 18px;
}
.img-box.background-none img {
  max-height: 95%;
  margin-bottom: 0;
}

.tarnsparent-bg {
  background: transparent !important;
}

/* paste url ends */
.text-capitalize {
  text-transform: capitalize;
}

.myDesign {
  display: flex;
  justify-content: center;
  align-items: center;
}
ul.category-section.font_comb_img li {
  width: 152px;
  height: 152px;
}
ul.category-section.font_comb_img li span.img-section.font-box,
ul.category-section.font_comb_img span.filter-box {
  height: 100%;
}
ul.category-section.font_comb_img li:last-child {
  margin-left: 0px;
}

/****RIGHT SIDEBAR*********/
.card-added-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(45deg, #9d00ff, #48e7fa) !important;
  color: #fff;
  border-radius: 5px;
  padding: 15px;
}
.mt {
  margin-top: 15px;
}
.payment-option {
  background: #414141;
  position: absolute;
  right: 0px;
  max-width: 350px;
  padding: 0px 15px 15px 15px;
  width: 100%;
  height: calc(100% - 72px);
  display: flex;
  flex-direction: column;
  /* z-index: 6; */
  z-index: 10;
  overflow-y: auto;
}
.details-row {
  display: flex;
  justify-content: space-between;
}
span.card-button-default,
span.card-button-remove {
  background: #fff;
  color: #808080;
  width: 97px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 2px;
  font-size: 13px;
  font-weight: 600;
  padding: 2px 0;
  transition: all 0.3s ease-in-out;
}
span.card-button-default:hover,
span.card-button-remove:hover {
  background: #4e4e4e;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #fff;
}
.details-row {
  margin-top: 11px;
}
.details-row:first-child {
  margin-top: 0px;
}
.card-added-box {
  /* margin-top: 30px; */
  margin-top: 15px;
}
.add-new-card {
  text-align: center;
}
.add-new-card-text {
  transition: all 0.3s ease-in-out;
}
.add-new-card-text:hover {
  color: #ddd;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.payment-option h6 {
  color: #fff;
  font-weight: normal;
  font-size: 14px;
}
form.subscription-card input {
  border: none;
}
form.subscription-card .input-border img,
form.subscription-card .cvc img {
  height: 20px;
  margin-right: 12px;
}
form.subscription-card .yr-mth img {
  height: 20px;
  margin-right: 12px;
}
.subscription-card h6,
.payment-option h6 {
  /* margin-top: 30px; */
  margin-top: 15px;
}
.subscription-card h5 {
  margin: 15px 0;
  color: #fff;
  text-transform: uppercase;
}
.payment-option ul.nav.nav-tabs li a:hover {
  color: #c3c3c3;
}
.payment-option .best-value {
  background: #fff;
}
.payment-option h4 {
  background: #848484;
  color: #fff;
  font-size: 18px;
  padding: 10px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.payment-option .best-value.active-colr {
  border: none;
}
.payment-option .best-value {
  margin-top: 30px;
}
span.saved-money b {
  color: #808080;
}
.btn-blue {
  background: #46cffb;
  margin-top: 15px;
  color: #fff;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}
.btn-blue:hover {
  background: #1bb6e8;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.card-added-box.new-card {
  padding: 0;
  height: 38px;
}
.details-row .active-default {
  background: #4e4e4e;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
span.card-button-remove {
  background: #fff;
  transition: all 0.3s ease-in-out;
  color: #d20303;
}
span.card-button-remove:hover {
  background: #d20303;
  transition: all 0.3s ease-in-out;
  color: #fff;
}

.card-name {
  text-transform: capitalize;
}
.parentDiv .form-control {
  display: flex;
  align-items: center;
}
/********RIGHT SIDEBAR ENDS***************/

/* elements fixes */
.templates-category .list_sm_image {
  box-shadow: none;
}
.stickers-titles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

/* elements fixes ends */

/* all frames layout fixes */
.all-frames-layouts .list_sm_image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  /* box-shadow: 0 0 14px #c7c7c7; */
  /* min-width: 198px; */
  box-shadow: unset;
  min-width: 100%;
}
.pb_15 {
  padding-bottom: 1.5rem !important;
}
.blur-image-1 {
  background-color: #6e3cca;
  /* width: 100%; */
  width: -webkit-fill-available;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.blur-image-2 {
  background-color: #007bff;
  /* width: 100%; */
  width: -webkit-fill-available;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.premium-template {
  position: absolute;
  top: 40%;
  z-index: 1;
  left: 15%;
  width: 100px;
  border-radius: 7px;
  box-shadow: 0 0 0 1px rgb(14 19 24 / 7%), 0 2px 12px rgb(14 19 24 / 20%);
}
/* ends-- */

/* saved designs fixes */

/* .canvas_list_item .blur-image-1,
.canvas_list_item .blur-image-2 {
  max-height: 185px;
}

.saved-design .blur-image-1,
.saved-design .blur-image-2 {
  max-height: 185px;
  max-width: 198px;
} */
.trending-design:hover .del-btn {
  opacity: 1;
  transform: scale(1);
}

.canvas_list_item:hover .del-btn {
  opacity: 1;
  transform: scale(1);
}

.myDesign:hover .del-btn {
  opacity: 1;
  transform: scale(1);
}

/*  */

/* swiper fixes */
.swiper-wrapper-custom {
  /* display: flex; */
  flex-direction: column;
  margin: 0 40px 40px 40px !important;
}

.all-frames-layouts .img-box {
  padding: 0;
  background: none;
}
/* swiper images fixes */

/* canvas wrappers */

span.c-number.read-only.card-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px red inset !important;
} */

/* input.form-control.bg-none.border-0:-webkit-autofill {
  -webkit-background-clip: text!important;
  color: #fff!important;
} */
input.form-control.bg-none.border-0:-webkit-autofill {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: #fff;
  caret-color: #fff;
}

.toolbar-otter .canvas-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 10px 0 18px;
}
.toolbar-otter .canvas-left-header {
  width: 7%;
}
.toolbar-otter .canvas-right-header {
  width: 80%;
  float: unset;
  display: flex;
  justify-content: flex-end;
}
.toolbar-otter .canvas-right-header ul li {
  font-weight: 400;
  width: unset;
  height: 36px;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: all 0.2s;
  font-size: 13px;
  padding-left: 8px;
  padding-right: 8px;
  /* margin-left: 8px;
  margin-right: 8px; */
}
.toolbar-otter .canvas-right-header ul li.font-color span,
.toolbar-otter .canvas-right-header ul li.background-color span {
  width: 10px !important;
  height: 10px !important;
}
.toolbar-otter .canvas-right-header ul li.trash.delete svg {
  height: 18px;
  width: 18px;
}
.toolbar-otter .canvas-right-header ul li.align .d-block svg {
  height: 22px;
  width: 22px;
}
.toolbar-otter .canvas-right-header ul li.font-family {
  width: 140px;
}
.toolbar-otter .canvas-right-header ul li.align .d-block {
  padding: 0;
}
.toolbar-otter .canvas-right-header ul li.transparency span.adjust {
  margin-right: 0;
}
.toolbar-otter .canvas-right-header ul li.background-color {
  padding-left: 4px;
}
.toolbar-otter .canvas-right-header ul li.font-color span.color-box {
  margin-left: 5px;
}
.toolbar-otter .canvas-header ul li.font-color:hover,
.toolbar-otter .canvas-header ul li.background-color:hover {
  background: unset;
}
.toolbar-otter .canvas-left-header svg {
  margin-right: 5px;
}
.toolbar-otter .canvas-header ul li.switch-corners {
  margin: 0;
}

.toolbar-otter .canvas-right-header .font-size,
.toolbar-otter .canvas-right-header .font-family {
  width: 55px;
  padding: 0;
  margin-right: 12px;
}

svg.header-svg-icon {
  margin-right: 8px;
  width: 25px;
}

.close-text-icon span {
  font-size: 30px;
  position: relative;
  top: 2px;
}

.create-new-meme {
  position: absolute;
  background-color: #7975f5;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 119px;
  border-radius: 50px;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
}

.create-new-meme span {
  color: #fff;
  margin-left: 5px;
}
