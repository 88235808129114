@font-face {
  font-family: "George Rounded";
  src: url("../new_fonts/GeorgeRoundedBold.woff2") format("woff2"),
    url("../new_fonts/GeorgeRoundedBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "George Rounded";
  src: url("../new_fonts/GeorgeRoundedRegular.woff2") format("woff2"),
    url("../new_fonts/GeorgeRoundedRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "George Rounded";
  src: url("../new_fonts/GeorgeRoundedSemibold.woff2") format("woff2"),
    url("../new_fonts/GeorgeRoundedSemibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* ========= global css ========*/
* {
  padding: 0;
  margin: 0;
}

body {
  background: #000;
  font-family: "George Rounded" !important;
  font-size: 14px;
  overflow-x: hidden;
}

ul {
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #414141; */
  background: #5f5f5f;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #717171;
}
:focus {
  border: none;
  outline: none;
  background: none;
}

button:focus {
  outline: none !important;
}
input:focus,
.btn:focus {
  box-shadow: none !important;
}

a {
  cursor: pointer;
}
a:hover {
  text-decoration: none;
}

/*====== global css ends =========*/

/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
                          HOME PAGE STYLES STARTS
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */
.small-screen {
  position: fixed;
  background-color: #282828;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  text-align: center;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
}
.logo_sm {
  margin-bottom: 6vh;
}

.button-sm a {
  display: inline-block;
  max-width: 200px;
  border-radius: 4px;
  overflow: hidden;
  margin: 0 10px;
}
.button-sm a img {
  width: 100%;
}

.list_heading {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  float: left;
  margin-bottom: 0px;
}
.canvas_list {
  display: flex;
  align-items: flex-end;
}
.canvas_list_item {
  color: #fff;
  text-align: center;
  /* margin-right: 30px;; */
  cursor: pointer;
  width: auto;
}

.templates .img-box {
  height: 185px !important;
  width: auto !important;
  border-radius: 0px !important;
  margin-bottom: 15px !important;
  overflow: hidden !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
  position: relative;
}

.custom-scroll {
  -webkit-scrollbar-color: hsla(0, 0%, 100%, 0.25) transparent;
  scrollbar-color: hsla(0, 0%, 100%, 0.25) transparent;
  scrollbar-width: thin;
  scroll-behavior: smooth;
}

.custom-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  scroll-behavior: smooth;
}

.custom-scroll::-webkit-scrollbar-track-piece {
  scroll-behavior: smooth;
}

.custom-scroll::-webkit-scrollbar-thumb:vertical {
  height: 20px;
  scroll-behavior: smooth;
}

.custom-scroll::-webkit-scrollbar-thumb:horizontal {
  height: 20px;
  scroll-behavior: smooth;
}

span.error-text {
  width: 100%;
  color: #afafaf;
  text-align: center;
  float: left;
}
.list_sm_image {
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  object-fit: contain;
}

.spinner-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.del-btn:hover svg {
  color: #fff !important;
  transition: all 0.3s ease-in-out;
}
.del-btn:hover {
  background: #820700;
  transition: all 0.3s ease-in-out;
}
.del-btn {
  background: #fff;
  height: 25px;
  width: 25px;
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 5px;
  transform: scale(0);
  transition: all 0.3s;
  opacity: 0;
}
.del-btn svg {
  color: #820700 !important;
  font-size: 15px !important;
}

@media (min-width: 1441px) {
  div#savedDesign .col-2 {
    max-width: 14%;
  }
}

.saved-design .img-box {
  width: auto;
  height: 185px;
}
a.see-all:hover {
  color: #dadada;
}
a.see-all {
  float: right;
}

.see-all {
  color: #fff;
  margin: 0px;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
}

/* ========= full page loader (component loader) ===== */
.page-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex: 1;
  flex-direction: column;
}
.page-loader img {
  margin-bottom: 20px;
}
/* ======== full page loader (component loader) ends ======= */

@media (max-width: 1199px) {
  .small-screen {
    display: flex;
  }
}
@media (min-width: 1366px) {
  .container {
    width: 1400px;
  }
}

/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
                          HOME PAGE STYLES ENDS
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */

/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
                          EDITOR PAGE STYLES STARTS
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*/

.canvas_list_item {
  color: #fff;
  text-align: center;
  /* margin-right: 30px; */
  cursor: pointer;
  width: auto;
}
.img-box {
  background: #202122;
  border-radius: 5px;
  margin-bottom: 15px;
}

.modal-backdrop.show {
  opacity: 0.7;
}

.memes-beta img {
  width: 167px;
}

.wrapper {
  position: relative;
}
.top-menu {
  background: #101010;
  padding: 0px 15px;
  height: 72px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.menu-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top-menu ul li {
  position: relative;
  width: 70px;
  display: inline;
  justify-content: center;
}
.menu-block ul li a {
  color: #fff;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}
ul.menu-left-ul li {
  margin-left: 20px;
}
ul.menu-left-ul li:first-child {
  margin: 0;
  border-right: 1px solid #4c4c4c;
  padding-right: 20px;
}
.custom-nav {
  background: #1c1c1e;
  height: auto;
  box-shadow: 0px 5px 20px 0px rgba(17, 17, 17, 0.35);
}
.modal-dialog .navbar-brand {
  padding-top: 0px;
  padding-bottom: 0px;
}
.modal-dialog .navbar-brand img {
  width: 15px;
}

.menu-block ul li a.download {
  color: #77e060;
}
.menu-block ul li a.share {
  color: #00d8e0;
}
.menu-block ul li a.share > img,
.menu-block ul li a.download > img {
  margin-right: 10px;
}
li.file span {
  display: inline-block;
  position: relative;
  margin-left: 10px;
  top: 3px;
}
ul.menu-left-ul li,
.print-section li {
  padding: 0px 0px;
}
li.download-li {
  border-right: 1px solid #4c4c4c;
  padding-right: 20px;
}
.top-menu ul li:hover {
  cursor: default;
}
.menu-block ul li:hover a {
  text-decoration: none;
}
.canvas-flex {
  display: flex;
  /*@canvas-screen-fit*/
  /* height: calc(100vh - 65px); */
}
aside {
  z-index: 1;
  display: flex;
  flex-direction: column;
  background: #202020;
}

div#sidebar-icons {
  display: flex;
  height: 100%;
  position: relative;
  /* width: 435px; @canvas-screen-fit*/
  width: 430px;
}
.sidebar-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #202020;
}
.sidebar-menu nav {
  height: 100%;
}
ul.left-icons-list li {
  position: relative;
  /* width: 85px; @canvas-screen-fit*/
  width: 80px;
  background: #808080;
}
ul.left-icons-list li a {
  position: relative;
  height: 82px;
  /* height: 77px;@canvas-screen-fit */
  width: 100%;
  background: #202020;
  display: flex;
  flex-direction: column;
  color: #fff;
  justify-content: center;
  transition: all 0.3s;
  font-size: 11px;
}
ul.left-icons-list li.user-img {
  position: absolute;
  /* width: 85px; @canvas-screen-fit*/
  width: 80px;
  background: #808080;
  bottom: 25px;
  background: none;
}
ul.left-icons-list li.user-img a {
  height: auto;
}
ul.left-icons-list li.user-img:hover,
ul.left-icons-list li.user-img:hover a {
  background: none;
}

ul.left-icons-list li a.active-catg,
ul.left-icons-list li:focus a.active-catg {
  background: #414141 !important;
}
ul.left-icons-list li a span {
  text-align: center;
  text-transform: uppercase;
  font-family: George Rounded;
  font-size: 10px;
  font-weight: 500;
  color: #c7c7c7;
}
ul.left-icons-list li span.d-block.photos {
  background: url("../images/icons/photos-icon.svg");
}
ul.left-icons-list li span.d-block.gifs {
  background: url("../images/icons/gifs-icon.svg");
}
ul.left-icons-list li span.d-block.templates {
  background: url("../images/icons/templates-icon.svg");
}
ul.left-icons-list li span.d-block.text {
  background: url("../images/icons/text-icon.svg");
}
ul.left-icons-list li span.d-block.uploads {
  background: url("../images/icons/uploads-icon.svg");
}
ul.left-icons-list li span.d-block.background {
  background: url("../images/icons/background-icon.svg");
}
ul.left-icons-list li span.d-block.draft {
  background: url("../images/icons/draft-icon.svg");
}
ul.left-icons-list li span.d-block.my-designs {
  background: url("../images/icons/designs-icon.svg");
}
ul.left-icons-list li span.d-block.element {
  background: url("../images/elements/elements_ic.svg");
}
ul.left-icons-list li span.d-block.editor {
  background: url("../images/icons/customize-icon.svg");
}
ul.left-icons-list span.d-block.photos,
ul.left-icons-list span.d-block.my-dsg,
ul.left-icons-list span.d-block.gifs,
ul.left-icons-list span.d-block.templates,
ul.left-icons-list span.d-block.uploads,
ul.left-icons-list span.d-block.text,
ul.left-icons-list span.d-block.background,
ul.left-icons-list span.d-block.draft,
ul.left-icons-list span.d-block.my-designs,
ul.left-icons-list span.d-block.element,
ul.left-icons-list span.d-block.editor {
  height: 28px;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: auto !important;
  width: 34px;
  align-self: center;
  margin-bottom: 4px;
}
ul.left-icons-list li:hover span.d-block.gifs {
  fill: #fff;
}
ul.left-icons-list li a:hover,
ul.left-icons-list li a:focus {
  background: #2f2f2f;
  color: #fff;
}
.rights-section-poster {
  background: #414141;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  /* left: 85px; @canvas-screen-fit*/
  left: 80px;
  width: 350px;
  padding-left: 10px;
  overflow-y: auto;
  padding-right: 10px;
  overflow-x: hidden;
}
.close-box-icon svg:hover {
  filter: sepia(0%) brightness(118%) contrast(120%);
}
.templates-category.mt-4 {
  height: 100%;
}
.search-box .round-search,
.search-box .round-search input {
  background: #2b2a2a;
  border-radius: 8px !important;
  /* background: linear-gradient(45deg, #9d00ff, #48e7fa) !important;
  border-radius: 50rem !important; */
}
.search-box input.form-control.bg-none.border-0::placeholder {
  color: #bababa;
  font-size: 14px;
}
.close-box-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 41px;
}

.cursor-pointer {
  cursor: pointer;
}

.search-box .round-search input {
  background: transparent !important;
}
.search-box .round-search {
  border-radius: 5px;
  height: 41px;
}
button#button-addon4,
button.search-close {
  width: auto !important;
  background: none !important;
  height: 31px;
  z-index: auto;
}
.search-box .round-search input,
.search-box .round-search input::placeholder {
  color: #fff;
  height: 41px;
}

.hide-arrow {
  position: absolute;
  right: -26px;
  height: auto;
  width: auto;
  top: 50%;
  cursor: pointer;
  z-index: -1;
}

ul.category-section.admin-li.mosiac img {
  max-width: 100%;
}

.right-canvas-section {
  background-color: #fff !important;
  background-image: url("../images/icons/background_svg.svg");
  width: 100%;
}
.print-section li.download-li:hover a,
.print-section li.share-li:hover a {
  color: #808080;
}
.canvas-header {
  background: #ffffff;
  float: left;
  width: 100%;
  height: 51px;

  align-items: center;
}
.canvas-left-header,
.canvas-right-header {
  display: inline-flex;
}
.canvas-right-header {
  float: right;
  margin-right: 10px;
}
.canvas-header ul {
  height: 50px;
  align-items: center;
  display: flex;
  margin-left: 10px;
}
.canvas-header ul li {
  display: flex;
  margin: 0 7px;
  transition: all 0.3s;
}
/* .canvas-header ul li,
.canvas-header ul li {
  font-weight: 400;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: all 0.2s;
  font-size: 13px;
  padding-left: 0;
  padding-right: 0;
} */
.canvas-header ul li.font-family {
  width: 120px;
}
.canvas-header ul li.font-family select,
li.font-size select {
  background: none;
  border: none;
}
.canvas-header li.font-size {
  width: 40px;
}
.canvas-header li.font-color,
.canvas-header li.background-color,
.canvas-header li.opacity,
.canvas-header li.switch-corners {
  border: none;
  width: auto;
  text-transform: capitalize;
  font-size: 12px;
}
.canvas-header ul li.switch-corners {
  margin: 0 8px;
}
.canvas-header li.font-color .text,
.canvas-header li.background-color .text,
.canvas-header li.opacity .text {
  margin-left: 30px;
  min-width: 62px;
  text-align: left;
}
.canvas-header ul li.switch-corners:hover,
.canvas-header ul li.transparency:hover {
  background: none;
}
.canvas-header ul li {
  /* border: 1px solid #616161; */
  border-radius: 5px;
}
.canvas-header ul .align:hover,
.canvas-header ul .color-box:hover,
.canvas-header ul .opacity-bar:hover,
.canvas-header ul .position-top:hover,
.canvas-header ul .position-bottom:hover,
.canvas-header ul .filter:hover,
li.flip-vh:hover {
  cursor: pointer;
}
.canvas-header ul li {
  margin: 0px 0px;
}

.canvas-header ul li.filter {
  width: 73px;
}
.canvas-header ul li.active-li {
  background: #565656;
  /* border: 1px solid #8e8e8e; */
}

span.color-box {
  border: 1px solid #616161;
  border-radius: 50%;
  margin: 0;
  background: none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  line-height: 14px;
}
li.font-color span.color-circle,
li.background-color span.color-circle {
  background: #3b9aff;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  float: left;
}
li.background-color span.color-circle {
  background: #ff3bb0;
}
li.font-color input,
li.background-color input,
li.opacity input {
  background: none;
  /* border: 1px solid #616161; */
  border: none;
  height: 36px;
  width: 94px;
  border-radius: 5px;
  text-align: center;
}

li.font-color input::placeholder,
li.background-color input::placeholder,
li.opacity input::placeholder {
  color: #c7c7c7;
}
span.opacity-bar {
  margin: 0 18px;
}

.canvas-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* height: calc(100vh - 127px); */
  height: calc(100vh - 123px);
  /*  overflow: auto;*/
  position: relative;
  flex: 1 1;
  /* added to fix height on frames editor */
  min-height: 600px;
}

div#india {
  display: flex;
}
.canvas-container {
  background: #fff;
  box-shadow: 0 0 5px #a5a5a5;
  position: relative;
  /* overflow: hidden; */
}

/********SWITCh-BUTTON***********/

.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 23px;
  margin-bottom: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

ul.category-section.filter-section li:hover span.filter-box {
  cursor: pointer;
}
span.filter-box {
  height: 77px;
}

span.insta-icon {
  border: 1px solid #616161;
  width: 23px;
  height: 23px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  border-radius: 2px;
  transition: all 0.2s ease-in-out;
  margin-top: 6px;
}
.instagram-edit {
  color: #fff;
}
.instagram-edit {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-family: George Rounded;
}
span.insta-icon:hover {
  cursor: pointer;
  background: #232222;
  transition: all 0.2s ease-in-out;
}
ul.category-section.admin-li li img {
  max-width: 100%;
  height: auto;
}
ul.category-section.admin-li li {
  width: 111px;
  display: inline-block;
  margin-bottom: 6px;
  height: auto;
}
ul.category-section.admin-li li span.img-section {
  height: auto;
}
ul.category-section.admin-li li:nth-child(2n) {
  margin-left: 3px;
}

button.btn.btn-primary.image-video.w-100 {
  background: #fff;
  border: none;
  color: #2f2f2f;
  font-size: 14px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff;
  background: none;
  border-bottom: 2px solid #fff;
}
.nav-tabs .nav-item {
  color: #ffffff;
}
.nav-tabs .nav-link {
  border: none;

  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.input-group.round-search,
ul#myTab,
.uploads-category {
  max-width: 320px;
}
a {
  color: #fff;
  text-decoration: none;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  color: #fff;
  border-bottom: 2px solid #fff;
}
.nav-tabs .nav-link.active:focus,
.nav-tabs .nav-link.active:hover {
  color: #fff;
  border-bottom: 2px solid #fff;
}
.nav-tabs {
  border-bottom: none !important;
}
.no-text {
  color: #fff;
  font-size: 13px;
}
.row.text-box.mt-2 span {
  color: #fff;
  font-size: 14px !important;
}
button.btn.heading-btn {
  background: #4e4e4e !important;
  color: #fff;
  text-align: left;
  border: 1px solid transparent !important;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: 0.2s all ease;
}
button.btn.heading-btn h2 {
  font-size: 26px;
}
button.btn.heading-btn h6 {
  font-size: 12px;
  font-weight: normal;
}
span.poster-heading {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
}
span.img-section {
  height: 113px;
  float: left;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  background: #3e3e3e;
  transition: 0.2s all ease;
}
.text-cat span.img-section {
  background: #4e4e4e;
}
.text-cat li:last-child {
  margin-left: 10px;
}

ul.category-section li {
  display: inline-block;
  display: inline-block;
  width: 113px;
  height: 113px;
}
span.filter-box {
  filter: contrast(0) brightness(10);
  -webkit-filter: contrast(0), brightness(10);
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
  width: 100%;
  float: left;
}
span.img-section img {
  width: 100%;
  object-fit: contain;
}
ul.category-section li:hover {
  cursor: pointer;
}
ul.category-section li:hover span.img-section.font-box {
  background: #5c5d5f;
  transition: all 0.3s;
}
span.poster-heading {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
}
.folders-edit-category.mt-3 .text-no-img {
  width: 100%;
  font-size: 20px;
  color: #e2e2e2;
  border: 2px dashed #e2e2e2;
  padding: 10px;
  border-radius: 5px;
}

.watermark-dialog {
  position: absolute;
  background: #2a2a2a;
  min-height: 100px;
  width: 285px;
  z-index: 1;
  /* bottom: 70px; */
  bottom: 55px;
  border-radius: 15px;
  box-shadow: 0 0 7px #b9b9b9;
  margin: 0 auto;
  padding: 20px;
  right: 20px;
}
.watermark-dialog-text {
  color: #fff;
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  margin: 18px 0;
}
.watermark-dialog-btn.btn {
  background: linear-gradient(45deg, #9d00ff, #48e7fa) !important;
  color: #fff;
  border-radius: 30px;
  width: 100%;
  border: navajowhite;
  max-width: 130px;
}

/**************TAG MODAL CSS*****************/
#tagModal .modal-content {
  background-color: #1a1a1a;
  border-radius: 8px;
  padding: 0 10px;
}
#tagModal .close {
  opacity: 1;
}
#tagModal .close:focus,
#tagModal .close:hover {
  outline: none;
  opacity: 0.8;
}
#tagModal .modal-content {
  max-width: 616px;
  margin: 0 auto;
}
#tagModal .modal-header,
#tagModal .modal-footer,
#share .modal-header {
  border: none;
}
.modal-header svg {
  width: 15px;
  height: 15px;
}

#tagModal textarea {
  background-color: #313131;
  border: none;
  border-radius: 3px;
  resize: none;
  color: #fff;
  font-weight: normal;
}
#tagModal textarea::placeholder {
  color: #a7a7a7;
  font-weight: normal;
  font-size: 16px;
}
#tagModal .modal-footer {
  padding-bottom: 47px;
  justify-content: center;
  margin-top: 26px;
}
#tagModal .modal-footer button {
  width: 150px;
  height: 46px;
  background: linear-gradient(to left, #8128ff, #29ebff);
  border: none;
  border-radius: 36px;
  font-size: 18px;
  /*font-weight: 600;*/
  /* font-family: Rubik; */
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

.tag-img {
  height: 114px;
  border-radius: 5px;
  border: 1px solid #3a3a3a;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 114px !important;
  min-width: 114px;
}
.tag-img img {
  max-width: 100%;
  object-fit: cover;
}

.tag-text {
  width: 100%;
  display: inline-flex;
  margin-left: 20px;
}
#tagModal .form-control:focus {
  box-shadow: none;
}
form#Tagpeople select,
form#Hastags select {
  width: 100%;
  background: none;
  border: 1px solid #8c8c8c;
  height: 52px;
  color: #a7a7a7;
  font-weight: normal;
  font-size: 17px;
  border-radius: 3px;
  cursor: pointer;
}
form#Tagpeople select:focus,
form#Hastags select:focus {
  box-shadow: none;
}
.tag-select-box.w-100 {
  margin-top: 27px;
}
.select-border-tags {
  position: relative;
  background: none;
  border-radius: 3px;
  height: 52px;
  width: 100%;
  z-index: 0;
}
.select-border-tags:hover {
  cursor: pointer;
}

.select-border-tags:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 9px solid #737171;
  content: "";
  position: absolute;
  right: 20px;
  top: 17px;
  z-index: 0;
  cursor: pointer;
}

#tagModal .modal-header {
  padding-top: 10px;
}
.small-tag-box.w-100,
.small-tag-people-box.w-100 {
  margin-top: 27px;
  max-height: 80px;
  overflow: auto;
  min-height: 41px;
}
button.btn.tags img {
  margin-left: 5px;
}
#tagModal button.btn.tags {
  min-width: 75px;
  height: 36px;
  background: linear-gradient(45deg, #9d00ff, #48e7fa);
  border: none;
  border-radius: 36px;
  color: #fff;
  font-size: 14px;
  /* font-weight: 600; */
  border-radius: 6px;
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 5px;
}
#tagModal .small-tag-people-box.w-100.mt-4 button.btn.tags {
  background: linear-gradient(45deg, #48e7fa, #9d00ff);
}
#tagModal button:hover {
  opacity: 0.9;
  transition: all 0.3s ease;
}
.tap-add {
  width: 150px;
}
button.btn.tags.tap-add img {
  margin-left: 0;
  margin-right: 5px;
}
#tagModal button.btn.tap-add,
#tagModal .small-tag-people-box.w-100.mt-4 button.btn.tap-add {
  background: #313131;
  float: right;
}
.menu-block ul li a.post {
  color: #eebd3a;
}
.menu-block ul li a.share > img,
.menu-block ul li a.download > img,
.menu-block ul li a.post > img {
  margin-right: 10px;
}
div#Tagpeople ul.test1,
div#Hastags ul.test1 {
  background: #f7f7f7;
  border-radius: 0px;
  padding: 5px 15px;
  top: 34px;
  z-index: 1;
  min-height: 0px;
  max-height: 165px;
  overflow-y: auto;
}
div#Tagpeople {
  z-index: 1;
}
.tag-img video {
  max-width: 100%;
}

ul.Unlimited-ul {
  margin: 10px 0 15px 0;
  display: inline-block;
}
.menu-block ul li a.download.pro-col {
  color: yellow;
}
button.btn.btn-try.w-100 {
  background: linear-gradient(45deg, #9d00ff, #48e7fa) !important;
  color: #fff;
  border: navajowhite;
  height: 40px;
}
span.payment.cc,
span.payment.pp {
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

span.payment.pp img {
  max-width: 80px;
}
#pro-modal img {
  height: 22px;
}
div#pro-modal input {
  border: none;
}
div#pro-modal img {
  margin-right: 12px;
}
div#pro-modal ul li {
  display: inline-block;
  line-height: 30px;
}
div#pro-modal p {
  line-height: 32px;
}
div#pro-modal p.recaptcha {
  line-height: 20px;
}
div#pro-modal ul.try-it li {
  font-size: 14px;
}
span.payment.cc,
span.payment.pp {
  border: 1px solid #ced4da;
  height: 48px;
  border-radius: 0.25rem;
  border-color: #b9b9b9;
}
#pro-modal .form-control {
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#pro-modal .cc-img img {
  width: 26px;
}
div#pro-modal span.payment.cc img {
  margin-right: 0;
  max-width: 100%;
  height: 36px;
}
.payment-method h6 {
  font-size: 14px;
}
p.us-resident {
  color: #b7b7b7;
  font-size: 15px;
}
p.recaptcha {
  color: #b7b7b7;
  font-size: 13px;
}
h4.modal-title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 16px;
}

div#pro-modal .modal-header {
  border: navajowhite;
}
div#pro-modal button.close,
div#memes-login-modal button.close {
  color: #fff;
  /* right: -50px; */
  right: -40px;
  position: absolute;
  background-color: rgb(121 121 121 / 40%);
  opacity: 1;
  border-radius: 50%;
  height: 35px;
  align-items: center;
  display: flex;
  width: 35px;
  justify-content: center;
  border: none;
  text-shadow: none;
  font-weight: 100;
  transition: all 0.3s ease-in-out;
  /* top: 0px; */
  top: -13px;
}
div#pro-modal button.close:focus,
div#memes-login-modal button.close:focus {
  outline: none;
  border: none;
}
div#pro-modal button.close:hover,
div#memes-login-modal button.close:hover {
  background-color: rgb(121 121 121 / 60%);
  transition: all 0.3s ease-in-out;
}
span.green-col {
  color: green;
}
.grey-col {
  color: #a5a5a5;
}
span.check {
  font-size: 30px;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  background: #57cc57;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}
.best-value.active-colr {
  border: 2px solid #9514fe;
}
.best-value {
  border-radius: 5px;
  border: 2px solid #ddd;
}
.best-value span {
  padding: 5px 10px;
}
.best-value h6 {
  background: #9514fe;
  color: #fff;
  font-weight: normal;
  font-size: 15px;
  padding: 10px;
  margin-bottom: 0px;
}
.best-value span,
.best-value.scnd-box span {
  padding: 6px 10px 4px 10px;
}
.best-value span:last-child,
.best-value.scnd-box span:last-child {
  padding-bottom: 6px;
  padding-top: 2px;
}
.best-value span:first-child,
.best-value.scnd-box span:first-child {
  padding-bottom: 0px;
}
.heading-title {
  float: left;
  width: 100%;
}
div#pro-modal .modal-body {
  padding: 0;
  /* added for UI height flick issue */
  /* min-height: 400px; */
}

.min-height-test {
  /* min-height: 480px; */
}
.bg-img {
  background: url("../images/payment/popup-bg.jpg");
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 20px;
  font-size: 18px;
  overflow: hidden;
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
}
a.bk-ar {
  float: left;
  width: 20px;
  margin-right: 7px;
  line-height: 29px;
}
a.bk-ar img {
  width: 100%;
}
div#pro-modal .modal-content {
  border-radius: 1rem;
}
.best-value.scnd-box span.save-btn {
  padding-left: 0;
}
input.card-heading::placeholder {
  color: #000;
}
.best-value.scnd-box {
  margin: 15px 0;
}
div#pro-modal .modal-content {
  border-radius: 1rem;
  background: #fff;
  border: none;
}
.StripeElement {
  width: 100%;
}
span.save-btn.cap-b b {
  color: #000;
}
b.thank-text {
  color: green;
  font-size: 26px;
  font-weight: normal;
}

.saved-cards-listing .details-row {
  margin-top: 0px;
}

.radio-left {
  display: flex;
  align-items: center;
  width: 26px;
}
.userSavedCards.saved-cards-listing .card-details {
  margin-left: 20px;
}
.userSavedCards.saved-cards-listing {
  max-height: 300px;
  overflow: auto;
  margin-bottom: 15px;
}

/**************CARD RADIO BUTTON***********/

/* The container */
.radio-left .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  width: auto;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-left .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
  width: 26px;
}

/* Create a custom radio button */
.radio-left .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #9c9c9c;
}

/* On mouse-over, add a grey background color */
.radio-left .container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-left .container input:checked ~ .checkmark {
  background-color: #fff;
  box-shadow: 0 0 4px #57cc57;
  border-color: #57cc57;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-left .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-left .container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-left .container .checkmark:after {
  top: 5px;
  left: 5px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #ff8d00;
}
/********************************/
div#pro-modal span.input-cvv {
  margin-top: 5px;
  color: #808080;
  border: none;
  width: 109px;
}
div#pro-modal span.input-cvv input {
  width: 100%;
  border: 1px solid #cecece;
  border-radius: 4px;
  height: 30px;
  padding-left: 5px;
}
.radio-left .container .checkmark:after {
  top: 4px;
  left: 4px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #57cc57;
}

div#pro-modal .card-added-box {
  background: #fff !important;
  color: #808080;
  padding-left: 0;
  padding-right: 0;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #cecece;
  border-radius: 0px;
  border-top: none;
  padding: 15px;
  border: 1px solid #cecece;
  margin: 0;
  border-bottom: 0;
}
div#pro-modal .card-added-box:first-child {
  margin-top: 0px;
}
div#pro-modal .card-added-box:last-child {
  border-bottom: 1px solid #cecece;
}
div#pro-modal .card-added-box.new-card.btn-bg {
  background: linear-gradient(45deg, #9d00ff, #48e7fa) !important;
  color: #fff;
  border-radius: 5px;
  border: none;
}
div#pro-modal .card-added-box.new-card.btn-bg:hover {
  cursor: pointer;
}
/* payment module ends */

/* undo-redo btn */
.un-rd {
  display: flex;
  position: absolute;
  left: 48%;
  top: 14px;
  font-size: 21px;
}
.un-rd svg:last-child {
  margin-left: 8px;
}
.un-rd svg:hover {
  cursor: pointer;
}
/* undo-redo btn  ends */

.design-image {
  width: 50px;
  height: 50px;
  border-radius: 35px;
  object-fit: cover;
}

.back-to-home-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 20px;
  align-items: center;
}
.back-to-home-wrapper .list_heading {
  margin-bottom: 0;
}
.back-to-home-wrapper .see-all {
  cursor: pointer;
}
.search-box .round-search input {
  background: transparent !important;
}
/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
                          EDITOR PAGE STYLES ENDS
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*/

/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
                          LOGIN TO MEMES MODAL STYLE
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*/
ul.login-ui {
  margin: 10px 0 15px 0;
  display: inline-block;
}
ul.login-ui li {
  display: block;
  line-height: 30px;
  color: #fff;
}
span.check {
  font-size: 30px;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  background: #57cc57;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}
#memes-login-modal .modal-header .close {
  color: #fff;
  opacity: 1;
}
button.btn.login-premium {
  background: linear-gradient(45deg, #9d00ff, #48e7fa) !important;
  color: #fff;
  border: none;
  height: 40px;
  max-width: 320px;
}
#memes-login-modal .modal-header,
#memes-login-modal .modal-footer {
  border: none;
}
#memes-login-modal .modal-header {
  padding: 15px 15px 0px 15px;
}
#memes-login-modal .modal-footer {
  justify-content: center;
}
#memes-login-modal .modal-header button.btn,
#memes-login-modal .modal-footer button.btn {
  outline: none;
  box-shadow: none;
}

div#memes-login-modal .modal-body {
  padding-top: 0;
  padding-bottom: 0;
}
#memes-login-modal .modal-header {
  padding-bottom: 5px;
  color: #fff;
}
#memes-login-modal .modal-content {
  border-radius: 1rem;
}
#memes-login-modal .modal-header h4 {
  margin-bottom: 0;
}
.login-memes-watermark {
  height: 40px;
  border-radius: 5px;
  margin-right: 10px;
}

.nt-yt,
.blu-clr {
  color: #fff;
  display: inline-block;
  margin-top: 8px;
}
.blu-clr a {
  color: #54c5fa;
}
.heading-title.heading-wt {
  color: #fff;
}
.bg-img.login-img {
  background: url("../images/bgImages/image-bg.png");
}
.bg-img.poster-bg-img {
  background: url("../images/bgImages/circle-bg.png");
}
.bg-img.login-img,
.bg-img.poster-bg-img {
  /* background: url("../images/bgImages/image-bg.png"); */
  height: 553px;
  background-repeat: no-repeat;
  background-position: right;
}
div#memes-login-modal ul.login-ui {
  position: relative;
}
div#memes-login-modal ul.login-ui li {
  margin-bottom: 15px;
}
div#memes-login-modal ul.login-ui span.check {
  background: none;
  height: auto;
  width: auto;
  vertical-align: baseline;
  position: absolute;
  min-width: auto;
  left: 0;
}
div#memes-login-modal ul.login-ui span {
  width: auto;
  position: relative;
  left: 35px;
}
div#memes-login-modal ul.login-ui li:last-child {
  margin-bottom: 0;
}
div#memes-login-modal .modal-body {
  padding: 0;
}

.white-poster-bg {
  background: #fff;
  /* width: 305px;
  height: 305px; */
  border-radius: 8px;
  box-shadow: 0 0 13px #e0e0e0;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
  overflow: hidden;
}

.white-poster-bg-inp {
  width: 305px;
  height: 305px;
}

.white-poster-bg-ins,
.white-poster-bg-pos {
  /* width: 305px; */
  height: 405px;
}

.white-poster-bg-ins img,
.white-poster-bg-pos img,
.white-poster-bg-inp img {
  max-height: 100%;
}

.white-poster-bg-pre img {
  max-width: 100%;
}

.white-poster-bg-ins video,
.white-poster-bg-pos video,
.white-poster-bg-inp video {
  max-height: 100%;
}

.white-poster-bg-pre video {
  max-width: 100%;
}

/* @@@@@@@@@@@ login on payment screens */
div#pro-modal .blu-clr a {
  color: #8f25fe;
}
div#pro-modal .blu-clr,
div#pro-modal .nt-yt {
  color: #000;
}
div#pro-modal .bg-img {
  /* height: 553px; */
}

#pro-modal .modal-body.sign-up-pro .bg-img {
  height: 500px;
}
div#pro-modal h5.modal-title {
  margin-top: 15px;
}

/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
                          LOGIN TO MEMES MODAL STYLE ENDS
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*/

/* @@@@@@@@@@@@@@@@@ layouts tab starts */
.social-boxes .social-editor-box {
  background: none;
}

.social-editor-box {
  position: relative;
}
.social-name-txt {
  text-transform: capitalize;
  color: #bdbdbd;
  font-size: 13px;
  margin-top: 5px;
  text-align: center;
}
.social-dimensions {
  color: #bdbdbd;
  font-size: 13px;
  margin-bottom: 7px;
}

.resize-wrapper {
  margin: 0;
  justify-content: space-between;
}
.resize-mr {
  margin-right: 6px;
}

.selected-meme-layout {
  border: 3px solid #985bfd;
}
.active-canvas-size-type {
  background: #985bfd !important;
}

.custom-dimensions-preview {
  /* height: 250px;
  width: 250px;
  margin: 0 auto; */
  height: 150px;
  /* width: 250px; */
  /* margin: 0 auto; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid yellow; */
}

.custom-dimensions-layout {
  max-height: 250px;
  /*max-width: 250px; */
  transform: scaleY(1);
  transform-origin: center;
  transition: max-height 1s;
  /* background: #e5feff; */
  overflow: hidden;
  /* max-height: 0; */
  /* background-color: #eee;

  -webkit-transform: scaleY(1);
  -o-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);

  -webkit-transform-origin: top;
  -o-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;

  -webkit-transition: -webkit-transform 0.26s ease-out;
  -o-transition: -o-transform 0.26s ease;
  -ms-transition: -ms-transform 0.26s ease;
  transition: transform 0.26s ease; */
}

.active-canvas-size-type svg {
  color: black;
}
.social-bg-color {
  background: #202122;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.customize-svgs svg {
  width: 100%;
  height: 100%;
}
div#custom-modal .modal-content {
  max-width: 400px;
  margin: 0 auto;
  height: 414px;
}
.modal-header .close img {
  max-width: 17px;
}
.modal-header .close {
  opacity: 1;
}
div#custom-modal .modal-header {
  border: none;
  color: #fff;
  padding-bottom: 0px;
}
div#custom-modal .error-div,
div#custom-modal .error-div .error {
  height: 37px;
}
input.form-control.custom-input {
  background: #1f1f1f;
  border: #1f1f1f;
  color: #fff;
}
input.form-control.custom-input:-webkit-autofill {
  -webkit-background-clip: text !important;
  color: #fff !important;
  caret-color: red;
}
input.form-control.custom-input::placeholder {
  color: #fff;
}
.width-custom {
  max-width: 168px;
}
.width-custom:last-child {
  margin-left: 12px;
}
div#custom-modal .done-custom {
  background: #8535ff;
  color: #fff;
  margin-left: 10px;
  width: 130px;
}
button.btn.cancel-custom {
  background: #fff;
}
.wh-ht {
  display: flex;
  justify-content: space-between;
}
div#custom-modal h4.modal-title {
  margin-bottom: 0px;
}

.social-editor-box .resize-pro-badge {
  position: absolute;
  right: -3px;
  top: -5px;
}
/* @@@@@@@@@@@@@@@@@@ layouts tab ends @@@@@@@@@@@@@@@@@@ */

/* background pattern css begins */
.background-patterns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap-reverse; */
  flex-wrap: wrap;
}

.background-pattern-item {
  width: 48%;
  margin-bottom: 10px;
}

.background-pattern-item img {
  width: 100%;
  cursor: "pointer";
}
/* background pattern css ends */

.design-category {
  color: #fff;
  position: absolute;
  bottom: -22px;
}

.unsupported-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #cccc;
  justify-content: center;
  display: flex;
  align-items: center;
}
